import ID from "@/types/common/Id";

export const PROFILE_INFO_URL = "/api/users/me";

export const USER_INFO_URL = (userID: ID) => {
  return `/api/users/${userID}`;
};
// export const PROFILE_INFO_URL = "/endpoints/auth/000profile.json";

// Уволить сотрудника 
export const CHANGE_USER_STATUS_URL = (userID: ID) => {
    return `/api/users/${userID}/status`;
  };

export const USER_ME_AVATAR_URL = "/api/users/me/avatar"