<template>
<!-- v-if="show" -->
<!-- <div  class="modal-shadow" @click.self="closeModal"> -->
<div  class="modal-shadow blurring">
    <!-- <div  class="modal-shadow" :class="{ 'blurring': blurring }"> -->
      <!-- <div  class="modal-shadow" :class="blurring ? 'blurring' : 'colornew'"> -->

      <!-- <transition name="popin" @after-leave="afterLeave"> -->
        <div  class="modal">
            <div class="modal-close" @click="closeModal">&#10006;</div>
            <slot name="title">
                <h3 class="modal-title" :class="colorTitle">{{titleText}}</h3>
            </slot>
            <slot name="body">
                <div class="modal-content" v-html="popupMsg"></div>
            </slot>
            <slot name="footer">
                <div class="modal-footer">
                    <button class="modal-footer__button" @click="closeModal">
                        Ок
                    </button>
                </div>
            </slot>
        </div>
      <!-- </transition> -->
    </div>
</template>

<script>
    import { messageOptions } from "@/types/common/message/MessageOptions";
    export default {
        name: "ModalWindow",
        data: function () {
            return {
                show: true,
                blurring: true,
            }
        },
        computed: {
          popupMsg(){
            return this.$store.state.popup.message
          },
          typeMessage(){

            return this.$store.state.popup.type
          },
          titleText(){
            // Заголовок сообщения
            if (this.$store.state.popup.type === messageOptions.ERROR){
              return 'Ошибка'
            }
            else if (this.$store.state.popup.type === messageOptions.WARNING) {
              return 'Предупреждение'
            }
            else {
              return ''
            }
          },
          colorTitle(){
            if (this.$store.state.popup.type === messageOptions.ERROR){
              return 'error'
            }
            else if (this.$store.state.popup.type === messageOptions.WARNING) {
              return 'warning'
            }
            else {
              return ''
            }
          }
        },
        props: {
          paramsData: {
            type: Object,
            default: () => {
              return {};
            },
          },
          message: {
            type: String,
            default: () => {
              return "";
            },
          },
          title: {
            type: String,
            default: () => {
              return "";
            },
          },
          disabled: {
            type: Boolean,
            default: () => {
              return false;
            },
          },
        },
        // beforeUnmount(){
        //   this.blurring = false
        //   alert('beforeUnmount')
        // },
        // unmounted() {
        //   console.log('unmounted')
        //   // alert('unmounted')
        // },
        // updated() {
        //   //  alert('updated')
        //    console.log('updated')
        // },

        methods: {
            afterLeave(){
              // this.$store.commit("popup/closeModal");
              // Событие после исчезновения объекта
              console.log('Очистка popup внутри')
              //this.$store.commit("popup/setMessage", "");
            },
            closeModal: function () {
                this.show = false
                this.blurring = false
                this.$store.commit("popup/closeModal");

                // this.$store.commit("popup/setMessage", "");

            },
            open() {
              console.log('OPEN')

              this.show = !this.show
              // let resolve;
              // let reject;
              // const popupPromise = new Promise((ok, fail) => {
              //   resolve = ok;
              //   reject = fail;
              // });
            }
        }

    }
</script>

<style scoped
       lang="scss">

  .error{
    color: red;
  }

  .warning{
    color: orange;
  }
  .black{
    color: black;
  }
  .popin-enter-active, .popin-leave-active {
    transition: opacity 0.7s;
    }
  .popin-enter, .popin-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }


/* .popin-enter-active {
  animation: popin-in .3s;
}
.popin-leave-active {
  animation: popin-in .3s reverse;
}
@keyframes popin-in { Изменяется размер, поэтому съезжает при анимации
  20% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
} */

    .blurring{
    background: rgba(0, 0, 0, 0.39);
    }
    .colornew{
    background: rgba(212, 19, 19, 0.39);
    }
    .modal-shadow {
        z-index: 99999;
        position: absolute;
        /* position: fixed; */
        top: 0;
        left: 0;
        min-height: 100%;
        width: 100%;

        /* animation: fade 5.6s; */
    }

    .modal {
        z-index: 99999;
        /* border: 2px solid #98ff98; */
        background: #fff;
        border-radius: 8px;
        padding: 15px;
        min-width: 350px;
        max-width: 480px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &-close {
            border-radius: 50%;
            color: #fff;
            background: #2a4cc7;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 7px;
            right: 7px;
            width: 30px;
            height: 30px;
            cursor: pointer;
        }

        /* &-title {
            color: #0971c7;
        } */

        &-content {
            margin-bottom: 20px;
            color: grey;
        }

        &-footer {
            &__button {
                background-color: #0971c7;
                color: #fff;
                border: none;
                text-align: center;
                padding: 8px;
                font-size: 17px;
                font-weight: 500;
                border-radius: 8px;
                min-width: 150px;
            }
        }
    }
</style>
