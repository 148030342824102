
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

import LogoSVG from "@/svg-icons/logoCrm.vue"

export default defineComponent({
	name: "AppSidebar",
	emits: ["update:show"],
	props: {
		show: {
			type: Boolean,
			default: (): boolean => {
				return false;
			},
		},
	},
	components: {
		LogoSVG,
	},
	// methods: {
	// 	Toggle(): void {
	// 		this.$emit("update:show", !this.show);
	// 	},
	// },

	setup(props, { emit }) {
		const store = useStore();

		const numberUnreadNotifications = computed<number>(() => {
			return store.state.common.numberUnreadNotifications
		});

		const isDemoMode = computed<boolean>(() => {
			if (store.state.common.isDemoMode) {
				return true;
			}
			return false;
		});

		function ToggleDemoMode(): void {
			store.dispatch("common/targetDemoAction");
		}

		function Toggle(): void {
			emit("update:show", !props.show);
		}

		onBeforeMount(() => {
			// GetAlertCountUnread();

		});

		return {
			Toggle,
			ToggleDemoMode,
			isDemoMode,
			store,
			numberUnreadNotifications,
			// getrrr,
			// isDemoModeGetter,
		};
	},
});
