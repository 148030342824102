//types
import { GetterTree } from "vuex";
import RootState from "@/types/store/RootState";
import CommonState from "./types";
import { CoicesShemaApps } from "@/types/server_info/ChoicesSchematic";

//getters
const getters: GetterTree<CommonState, RootState> = {
    isDemo: (state): boolean => {
		return state.isDemoMode;
	},
	numberUnreadNotifications: (state): number => {
		return state.numberUnreadNotifications;
	},
	choicesServerInfo: (state): CoicesShemaApps|null => {
		return state.choicesServerInfo;
	},
};
export default getters;
