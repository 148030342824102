import { get_choices_schematic_server_info } from "@/api/server_info";
import { CoicesShemaApps } from "@/types/server_info/ChoicesSchematic";
import { Store } from "vuex";

/**
 * Получить схему проекта и записать в store
 */
export function GetChoicesSchematicServerInfo(store: Store<any>): void{
    get_choices_schematic_server_info().then((data) => {
        const choicesServerInfo: CoicesShemaApps = data.data["SCHEMA"];
        store.dispatch("common/setChoicesServerInfoAction", choicesServerInfo);
    });
}