import { ErrorsData } from "@/validations/types/ErrorsData";

// ChangingStatusErrorInRepository
// AddOrRemoveErrorInRepository
/**
 * Запись сообщения об ошибке в объект errors.
 * Если error_a не передан, тогда из хранилища удаляется
 *  ошибка с ключом name_key_error
 *
 * @param name_key_error
 * @param error_a  // Список ошибок, ключом которого является name_key_error
 * @param errors  // Хранилище всех ошибок
 */
export function AddOrRemoveErrorInRepository(
    name_key_error = "",
    error_a: Array<string>=[],
    errors: ErrorsData
  ): void {

    console.debug(error_a)
    console.debug(errors)
  if(error_a.length === 0 && name_key_error){
    delete errors[name_key_error];
    console.warn(`Очистка ошибки ${name_key_error}`, errors);
  }else{
    if (error_a.length > 0 && name_key_error) {
      console.warn(`error_a.length > 0 ${name_key_error}`, errors);
      errors[name_key_error] = error_a;
    }
  }


}

// SetError(name_key_error = "", error_a: Array<string>): void {
//   // Запись сообщения об ошибке в data
//   if (error_a.length > 0 && name_key_error) {
//     this.errors[name_key_error] = error_a;
//   } else if (name_key_error) {
//     console.warn("DEL состояние родительской ошибки", this.errors);
//     delete this.errors[name_key_error];
//   }
// },
