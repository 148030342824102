
import { defineComponent } from "vue";
// import AppHeader from "@/components/includes/AppHeader.vue";

export default defineComponent({
	name: "DefaultLayout",
  components: {
		// AppHeader,
	},
	methods:{
		test(): void{
			console.log('test default layout');
			//this.$router.go(-1);
			//this.$router.back();
		}

	},
  mounted() {
		this.test();
	},
});
