
import { defineComponent, ref, watch } from "vue";
import SearchInput from "@/components/form-elements/inputs/SearchInput.vue";
import SearchGlobalModal from "@/components/modal_windows/search/SearchGlobalModal.vue"
import { debounce } from "lodash";
import { messageOptions } from "@/types/common/message/MessageOptions";
import { SearchAllPayload } from "@/types/search/Offer";
import { search_global } from "@/api/search";
import { useStore } from "vuex";
import { AxiosError } from "axios";
import AppLoading from "@/components/loaders/AppLoading.vue";
import ContentLoader from "@/components/loaders/ContentLoader.vue";
import BaseLoader from "@/components/loaders/BaseLoader.vue";



export default defineComponent({
	name: "SearchGlobalInput",
	emits: ["update:modelValue", "search", "clear-input"],
	components: {
		SearchInput,
		SearchGlobalModal,
		ContentLoader,
		BaseLoader,
	},
	// props: {
	// 	placeholder: {
	// 		type: String,
	// 		default: (): string => {
	// 			return "";
	// 		},
	// 	},
	// 	modelValue: {
	// 		type: [String, Number],
	// 		default: (): string => {
	// 			return "";
	// 		},
	// 	},
	// },

	setup(props, { emit }) {
		// function UpdateValue(e: InputEvent): void {
		// 	const value = (e.target as HTMLInputElement).value;
		// 	console.debug("UpdateValue", value);
		// 	emit("update:modelValue", value);
		// 	if (!value){
		// 		// alert('Empty')
		// 		emit('clear-input')
		// 	}
		// }
		const store = useStore();


		const searchString = ref<string>('');
		const resultSearchAll = ref<any>(null);

		const showSearchBlock = ref<boolean>(false)
		const loading = ref<boolean>(false)

		function ResetSearch(): void {
			// alert("RunSearch");
			searchString.value = "";
			showSearchBlock.value = false
			// filter.text = "";
		}

		const AutoRunSearch = debounce(() => {
			if (searchString.value){
				searchAll();
			}
			console.log("debug");
			
		}, 800);

		watch(searchString, async () => {
			// _.debounce(AutoRunSearch, 700)
			AutoRunSearch();
			// getRealtyObjectsOrders()
		});
		function CloseSearchGlobalModal(): void{
			showSearchBlock.value = false
		}

		function searchAll(): void{
			loading.value = true
			const search_params: SearchAllPayload = {
				search: searchString.value
			}
			showSearchBlock.value = false
			search_global(search_params).then(({ data }) => {
					loading.value = false

					console.debug("searchAll", data);
					// alert('search OK')
					resultSearchAll.value = data
					showSearchBlock.value = true
				})
				.catch((error) => {
					// alert('search ERROR')
					showSearchBlock.value = false


					console.error(error);
					// loading.value = false;

					let message = ""
					switch ((error as AxiosError)?.response?.status) {
						case 502: // 502
							message = "Ошибка интернет соединения";
							break;
						case 500: // 500
							message =
								"Внутренняя ошибка сервера, попробуйте повторить запрос позже";
							break;
						case 403: // 403
							message = 'У вас недостаточно прав';
							store.dispatch("popup/showModalAlert", {
								text: message,
								type: messageOptions.ERROR,
							});
							break;
						case 400: // 403
							message = `${error.response.data.detail}`;
							store.dispatch("popup/showModalAlert", {
								text: message,
								type: messageOptions.ERROR,
							});
							break;
						default:
							message =
								"Произошла ошибка, попробуйте повторить позже или обратитесь в службу поддержки";
							store.dispatch("popup/showModalAlert", {
								text: message,
								type: messageOptions.ERROR,
							});
					}

					loading.value = false

				});
		}

		function ClickSearchInput(): void{
			if (searchString.value){
				searchAll()
			}
		}

		return {
			loading,
			emit,
			searchString,
			searchAll,
			ResetSearch,
			ClickSearchInput,
			showSearchBlock,
			resultSearchAll,
			CloseSearchGlobalModal,
		};
	},
});
