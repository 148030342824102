import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_window = _resolveComponent("modal-window")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, {
      name: "popup-fade",
      onAfterLeave: _ctx.afterLeave
    }, {
      default: _withCtx(() => [
        (_ctx.$store.state.popup.modalVisible)
          ? (_openBlock(), _createBlock(_component_modal_window, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onAfterLeave"]),
    _createVNode(_component_LayoutBase)
  ], 64))
}