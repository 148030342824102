import api from "@/instances/api";
//types
import { AxiosResponse } from "axios";
//urls
import { CHANGE_USER_STATUS_URL, PROFILE_INFO_URL, USER_INFO_URL, USER_ME_AVATAR_URL } from "@/api/user/urls";
import ID from "@/types/common/Id";
import { UserData } from "@/types/user/User";

export function get_profile_info(): Promise<AxiosResponse> {
	return api.get(PROFILE_INFO_URL);
}

// Блоктровать/Разблокировать пользователя
export function change_user_status(userID: ID, is_active: boolean): Promise<AxiosResponse> {

	return api.patch(
		CHANGE_USER_STATUS_URL(userID),
		{ is_active: is_active },
	);
}

//Обновить данные профиля пользователя
export function update_me_profile_info(data: UserData): Promise<AxiosResponse> {

	return api.patch(
		// COMPANY_PROFILE_URL(company_id),
		PROFILE_INFO_URL,
		data,
	);
	// return api.get(EDIT_OFFICE_URL, { params: data });
}

/**
 * Загрузка аватара пользователя
 */
export function upload_user_me_avatar(
	// company_data: Company,
	avatar: File
): Promise<AxiosResponse> {
	// const company_id = company_data.id
	const formData = new FormData();
	formData.append('avatar', avatar);
	return api.patch(
		USER_ME_AVATAR_URL, formData,
	);
}

//Обновить данные профиля пользователя
export function update_user_info(userID: ID, data: UserData): Promise<AxiosResponse> {

	return api.patch(
		// COMPANY_PROFILE_URL(company_id),
		USER_INFO_URL(userID),
		data,
	);
	// return api.get(EDIT_OFFICE_URL, { params: data });
}