
import { defineComponent } from "vue";
import BaseLoader from "@/components/loaders/BaseLoader.vue";


export default defineComponent({
	name: "SearchInput",
	emits: ["update:modelValue", "search", "clear-input"],
	components: {
		// SearchInput,
		// SearchGlobalModal,
		// ContentLoader,
		BaseLoader,
	},
	props: {
		placeholder: {
			type: String,
			default: (): string => {
				return "";
			},
		},
		modelValue: {
			type: [String, Number],
			default: (): string => {
				return "";
			},
		},
		showSpinner:{
			type: Boolean,
			default: false,
		}
	},

	setup(props, { emit }) {
		function UpdateValue(e: InputEvent): void {
			const value = (e.target as HTMLInputElement).value;
			console.debug("UpdateValue", value);
			emit("update:modelValue", value);
			if (!value){
				// alert('Empty')
				emit('clear-input')
			}
		}

		return { UpdateValue, emit };
	},
});
