import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-223163ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "profile-layout"
}
const _hoisted_2 = { class: "profile-layout__main__content scroll-styled" }
const _hoisted_3 = { class: "content-box" }
const _hoisted_4 = { class: "content-box__title page-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_loading = _resolveComponent("app-loading")!
  const _component_app_sidebar = _resolveComponent("app-sidebar")!
  const _component_app_header = _resolveComponent("app-header")!
  const _component_app_header_mobile = _resolveComponent("app-header-mobile")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_scroll_to_top_icon = _resolveComponent("scroll-to-top-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_app_loading, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_app_sidebar, {
            show: _ctx.showSidebar,
            "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSidebar = $event))
          }, null, 8, ["show"]),
          _createElementVNode("div", {
            class: _normalizeClass(["profile-layout__main", { 'full-size': !_ctx.showSidebar }])
          }, [
            _createVNode(_component_app_header),
            _createVNode(_component_app_header_mobile),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.computedTitleMeta) + " № " + _toDisplayString(_ctx.id), 1),
                _createElementVNode("div", {
                  class: _normalizeClass(["content-box__box", { 'default-radius': !_ctx.componentIsCompany, 'radius-for-tabs': _ctx.componentIsCompany }])
                }, [
                  _createVNode(_component_router_view, null, {
                    default: _withCtx(({ Component }) => [
                      _createVNode(_Transition, {
                        name: "slide-fade",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  })
                ], 2)
              ]),
              _withDirectives(_createElementVNode("div", {
                class: "profile-layout__to-top",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ScrollToTop && _ctx.ScrollToTop(...args)))
              }, [
                _createVNode(_component_scroll_to_top_icon)
              ], 512), [
                [_vShow, _ctx.scrollHeight > 500]
              ])
            ])
          ], 2)
        ]))
  ]))
}