//types
import { ActionTree } from "vuex";
import PopupState from "@/store/modules/Popup/types";
import RootState from "@/types/store/RootState";
import { messageOptions } from "@/types/common/message/MessageOptions";
// import setMessage from "@/store/modules/Popup/mutations"

//actions
const actions: ActionTree<PopupState, RootState> = {
  showModalAlert(ctx , {text='', type=messageOptions.INFO}): void {
    console.log('showModalAlert')
		// state.message = text;
    // state.modalVisible = true;
    // setMessage({state}, 'ewfe')
      //alert('showModal Mutation')
      // state.modalComponent = componentName;
      // componentName.open()

    ctx.commit('setMessage', text)
    ctx.commit('setTypeAlert', type)


    ctx.commit('showModal', 'текст мутации')

	},
};

export default actions;
