
import {
	computed,
	defineComponent,
	onBeforeMount,
	onMounted,
	PropType,
	ref,
	watch,
} from "vue";
//components
import BaseOneSelect from "@/components/form-elements/selects/BaseOneSelect.vue";
import BaseButton from "@/components/form-elements/buttons/BaseButton.vue";
// Функции
import { AddOrRemoveErrorInRepository } from "@/services/ErrorsManager";
//
import ValidationErrors from "@/validations/types/ValidationErrors";
import { get_list_of_employees_to_transfer } from "@/api/company";
import { EmployeeUserData } from "@/types/company/EmployeeUser";
import { AxiosError } from "axios";
import { useStore } from "vuex";
import { messageOptions } from "@/types/common/message/MessageOptions";
// import AppLoading from "@/components/loaders/AppLoading.vue";
// import ContentLoader from "@/components/loaders/ContentLoader.vue";

import ID from "@/types/common/Id";
import { OnClickOutside } from "@vueuse/components";
import RealtyObjectOrder from "@/types/orders/RealtyObjectOrder";
import { Choices, CoicesOrder } from "@/types/orders/CoicesOrder";
import { transfer_client_order } from "@/api/orders";
import { get_offers, get_my_offers } from "@/api/offers";
import { FilterOffersPayload } from "@/types/offers/PayloadOpts";
import OfferCompact from "@/types/offers/Offer";
import { formatPrice } from "@/helpers/formatPrice";
import { getEmployeeFI } from "@/services/employees/getUserData";
import Pagination from "@/components/common/PaginationItem.vue";
import ConfirmPopup from "@/components/common/ConfirmPopup.vue";
import { create_deal } from "@/api/deals";
import { CreateDealPayload } from "@/types/deals/PayloadOpts";
import { SearchAllPayload, searchOffer, searchOffersList } from "@/types/search/Offer";
import { SearcGlobalhAll } from "@/types/search/Common";
import { search_global, search_offers, search_orders } from "@/api/search";
import OrderDetailSearch, { searchOrdersList } from "@/types/search/Order";



// import { FIO } from "@/types/user/User";
export default defineComponent({
	name: "SearchGlobalModal",
	emits: ["close", "reload-employees", "action-on-success"], //emits: ["invite-employee"],
	components: {
		OnClickOutside,
		// AppLoading,
		// ContentLoader,
		// BaseButton,
		Pagination,
		ConfirmPopup,
	},
	props: {
		resultSearchAll: {
			type: Object as PropType<SearcGlobalhAll>, // PropType<RealtyObjectOrder>,
			default: null,
		},
		
		searchString: {
			type: String,
			required: true,
		},

		loader: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {

		watch(props.resultSearchAll, async (newValue) => {
			// alert('resultSearchAll')
			console.debug("resultSearchAll", newValue);
		});

		const offersResult = ref<searchOffersList>({} as searchOffersList)
		const offersNewStep = ref<searchOffer[]>({} as searchOffer[])
		const offers = ref<searchOffer[]>({} as searchOffer[])

		const offerNextPage = ref<number>()
		const offerCount = ref<number|null>(null)


		const ordersResult = ref<searchOrdersList>({} as searchOrdersList)
		const ordersNewStep = ref<OrderDetailSearch[]>({} as OrderDetailSearch[])
		const orders = ref<OrderDetailSearch[]>({} as OrderDetailSearch[])

		const orderNextPage = ref<number>()
		const orderCount = ref<number|null>(null)


		const store = useStore();
		const errors = ref<ValidationErrors>({} as ValidationErrors);
		const loading = ref<boolean>(false);
		const blur = ref<boolean>(false);
		const onlyMyOffers = ref<boolean>(false);

		const showConfirm = ref<boolean>(false);
		const textConfirmPopup = ref<string>();

		const show = ref<boolean>(false);


		// Пагинация
		const currentPage = ref<number>(1); // Текущая страница
		const showOnPage = ref<number>(10); // Кол-во на странице
		const countItems = ref<number>(0); // Всего объектов

		const filter = ref<FilterOffersPayload>({
			//search: "",
			stage: "common,contract,deposit",
		});
		const payloadFilter = ref<FilterOffersPayload>({});

		const numberOffers = ref<number>(0);

		const selectedOfferId = ref<number>();

		function selectOffer(offerId: number): void {
			selectedOfferId.value = offerId;
			const msg = `Вы подтверждаете выбор объекта №${selectedOfferId.value}`;
			textConfirmPopup.value = msg;
			showConfirm.value = true;
		}

		function Close(): void {
			console.log("Close --- Выбрать исполнителя");
			emit("close");
		}

		function Send(): void {
			console.log("Отправка приглашения...");
			// Валидация полей
			//
		}

		//Валидация полей
		function Validate(): boolean {
			const errors_validate = {};
			// if (!new_executor_id.value){
			//   errors_validate = {
			//     'executor_id': ['Выберите сотрудника!']
			//   }
			// }
			errors.value = {
				...errors_validate,
			};
			return (
				Object.keys(errors.value).length === 0 &&
				Object.keys(errors_validate).length === 0
			);
		}
		function SetError(name_key_error = "", error_a: Array<string>): void {
			console.debug(name_key_error, error_a);
			AddOrRemoveErrorInRepository(name_key_error, error_a, errors.value);
		}

		// onBeforeMount(() => {
		// 	console.log("onBeforeMount!");

		// 	offers.value = props.resultSearchAll.offers.result
		// 	// getOffers();
		// });
		function searchNextPage(type: string): void{

			let page
			let search_func

			switch (type) {
				case "offers":
					page = offerNextPage.value
					search_func = search_offers

					break;
				case "orders":
					page = orderNextPage.value
					search_func = search_orders
					break;
				default:
					console.log("Invalid operator");
					return
			}
			// const page = offerNextPage.value
			const search_params: SearchAllPayload = {
				search: props.searchString,
				page: page,
			}
			search_func(search_params).then(({ data }) => {
					console.debug("search_func", data);

					const new_part = data.results

					switch (type) {
						case "offers":
							offerNextPage.value = data.next_page_number
							Array.prototype.push.apply(offers.value, new_part);
							break;
						case "orders":
							orderNextPage.value = data.next_page_number
							Array.prototype.push.apply(orders.value, new_part);
							break;
						default:
							console.log("Invalid operator");
					}

					// console.debug('offer_old', offers.value)
					// console.debug('new_part_offer', new_part)
					// console.debug('offers_NEW', offers.value);
				})
				.catch((error) => {
					// alert('search ERROR search_offers')
					// showSearchBlock.value = false


					console.error(error);
					// loading.value = false;

					let message = ""
					switch ((error as AxiosError)?.response?.status) {
						case 502: // 502
							message = "Ошибка интернет соединения";
							break;
						case 500: // 500
							message =
								"Внутренняя ошибка сервера, попробуйте повторить запрос позже";
							break;
						case 403: // 403
							message = 'У вас недостаточно прав';
							store.dispatch("popup/showModalAlert", {
								text: message,
								type: messageOptions.ERROR,
							});
							break;
						case 400: // 403
							message = `${error.response.data.detail}`;
							store.dispatch("popup/showModalAlert", {
								text: message,
								type: messageOptions.ERROR,
							});
							break;
						default:
							message =
								"Произошла ошибка, попробуйте повторить позже или обратитесь в службу поддержки";
							store.dispatch("popup/showModalAlert", {
								text: message,
								type: messageOptions.ERROR,
							});
					}
				});
		}

		// searchOrderNextPage
		
		
		onMounted(() => {
			console.log("onBeforeMount!");

			offers.value = props.resultSearchAll.offers.results
			offerCount.value = props.resultSearchAll.offers.count
			offerNextPage.value = props.resultSearchAll.offers.next_page_number
			// getOffers();

			orders.value = props.resultSearchAll.orders.results
			orderCount.value = props.resultSearchAll.orders.count
			orderNextPage.value = props.resultSearchAll.orders.next_page_number

		});

		return {
			ordersNewStep,
			orderNextPage,
			orderCount,
			orders,
			//
			searchNextPage,
			offerNextPage,
			offerCount,
			offers,
			offersNewStep,
			offersResult,
			// addOfferInDeal,
			showConfirm,
			textConfirmPopup,
			selectOffer,
			// clickMyOfferButton,
			//pagination
			showOnPage,
			currentPage,
			countItems,
			// OnChangePage,

			//
			onlyMyOffers,
			// getMyOffers,
			// getObjectType,
			filter,
			getEmployeeFI,
			formatPrice,
			blur,
			loading,
			errors,
			Close,
			Send,
			Validate,
			SetError,
			AddOrRemoveErrorInRepository,
		};
	},
});
