import { Router } from "vue-router";
//Этот слой пройдет только авторизованный пользователь
export default async function Auth(router: Router): Promise<boolean> {
	const isAuth = localStorage.getItem("token");
	if (isAuth) {
		return true;
	} else {
		await router.push({ name: "Authorization" });
		return false;
	}
}
