
import {
	computed,
	defineComponent,
	onBeforeMount,
	onMounted,
	onUpdated,
	ref,
} from "vue";
//api
import { get_profile_info } from "@/api/user";
//components
import AppLoading from "@/components/loaders/AppLoading.vue";
import AppSidebar from "@/components/includes/AppSidebar.vue";
import AppHeader from "@/components/includes/AppHeader.vue";
import AppHeaderMobile from "@/components/includes/AppHeaderMobile.vue";
import { Store, useStore } from "vuex";
import { useRouter } from "vue-router";

import { UserData } from "@/types/user/User";
import { get_choices_schematic_server_info } from "@/api/server_info";
import { CoicesShemaApps } from "@/types/server_info/ChoicesSchematic";
import { GetChoicesSchematicServerInfo } from "@/helpers/SchematicServerInfo";

export default defineComponent({
	name: "ProfileLayout",
	components: {
		AppSidebar,
		AppLoading,
		AppHeader,
		AppHeaderMobile,
	},

	setup() {
		const router = useRouter();

		// const id = ref<string | string[]>(router.currentRoute.value.params.id);
		const id = computed<string | string[]>(() => {
			// if (router.currentRoute.value.meta.title) {
			// 	return router.currentRoute.value.meta.title;
			// }
			return router.currentRoute.value.params.id;
		});

		const computedTitleMeta = computed<string>(() => {
			if (router.currentRoute.value.meta.title) {
				return router.currentRoute.value.meta.title;
			}
			return "";
		});

		const componentIsCompany = computed<boolean>(() => {
			if (router.currentRoute.value.name === "Company") {
				return true;
			}
			return false;
		});

		const store = useStore();
		const numberUnreadNotifications = computed<number>(() => {
			return store.state.common.numberUnreadNotifications
		});

		const loading = ref<boolean>(true);
		const scrollHeight = ref<number>(0);
		const showSidebar = ref<boolean>(false);

		//Получить информацию о пользователе
		function GetProfileInfo(): void {
			console.log("Получить информацию о пользователе");
			loading.value = true;
			get_profile_info().then(({ data }) => {
				console.log("u data", data);
				const UserData: UserData = data.User;
				store.commit("auth/SET_USER_DATA", UserData);
				loading.value = false;
			});
		}

		//Проскроллить окно вверх
		function ScrollToTop(): void {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}

		//Сохранение позиции скролла
		function SaveScroll(): void {
			scrollHeight.value = window.scrollY;
		}

		function goToAlerts(event: any): void {
			router.push({ path: "/alerts" });
		}

		onBeforeMount(() => {
			console.debug("onBeforeMount ProfileLayout");
		});

		onMounted(() => {
			console.debug("onMounted ProfileLayout");
			GetProfileInfo();
			window.addEventListener("scroll", SaveScroll);

			GetChoicesSchematicServerInfo(store)
		});

		onUpdated(() => {
			console.debug("onUpdated ProfileLayout");
			// GetProfileInfo();
			// window.addEventListener("scroll", SaveScroll);
		});

		return {
			numberUnreadNotifications,
			loading,
			scrollHeight,
			showSidebar,
			store,

			GetProfileInfo,
			ScrollToTop,
			SaveScroll,
			goToAlerts,

			computedTitleMeta,
			componentIsCompany,
			id,
		};
	},
});
