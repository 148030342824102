
import { defineComponent } from "vue";

export default defineComponent({
	name: "SrcAvatar",
	props: {
		srcAvatar: {
			type: String,
			default: "",
		},
	},
});
