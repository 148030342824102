//export const SEND_LOGIN_URL = "/endpoints/auth/login.json";

//export const SEND_LOGIN_URL = "/api/auth/login";

export const SEND_LOGIN_URL = "/api/auth/new/send_verification_code"
export const AUTHORIZE_URL = "/api/auth/login";

// export const AUTHORIZE_URL = "/endpoints/auth/authorize.json";
// export const SEND_CODE_URL = "/endpoints/auth/code.json";
export const SEND_CODE_URL = "api/auth/new/send_verification_code";
// export const SEND_OGRN13_URL = "/endpoints/auth/ogrn13.json";
export const SEND_OGRN_URL = "/api/companies/check_company_field";
export const SEND_OGRN13_URL = "/api/companies/check_company_field";
// export const SEND_OGRN15_URL = "/endpoints/auth/ogrn15.json";
export const SEND_OGRN15_URL = "/api/companies/check_company_field";
export const UPDATE_CODE_URL = "/endpointsauth/update_code.json";
// export const REGISTER_COMPANY_URL = "/endpoints/company/company.json";
export const REGISTER_COMPANY_URL = "/api/companies/companytest";
export const REGISTER_ENTREPRENEUR_URL = "/endpoints/company/entrepreneur.json";
export const REGISTER_AGENT_URL = "/endpoints/company/agent.json";
// export const REFRESH_TOKEN_URL = "/endpoints/auth/refresh.json";
export const REFRESH_TOKEN_URL = "/api/jwt/token/refresh/";
