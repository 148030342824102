//types
import { MutationTree } from "vuex";
import AuthState from "@/store/modules/Auth/types";
// import User from "@/types/auth/User";
import { UserData } from "@/types/user/User";
//mutation
const mutations: MutationTree<AuthState> = {
	AuthorizeUser(state, payload: UserData | null): void {
		state.isAuth = true;
		state.User = payload;
	},
	Logout(state): void {
		state.isAuth = false;
		localStorage.removeItem("token");
		localStorage.removeItem("refresh_token");
	},
	SET_USER_DATA(state, payload: UserData): void {
		state.User = payload;
	},
	SET_USER_AVATAR(state, str: string): void {
		if(state.User){
			state.User.avatar = str;
		}
		else{
			console.warn('Нет в state.User')
		}
	},
};
export default mutations;
