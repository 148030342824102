
import { defineComponent } from "vue";

export default defineComponent({
  name: "chevronLeft",
  props: {
    color: {
      type: String,
      default: (): string => {
        return "black";
      },
    },
    width: {
      type: Number,
      default: (): number => {
        return 16;
      },
    },
    height: {
      type: Number,
      default: (): number => {
        return 16;
      },
    },
  },
});
