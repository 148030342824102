//types
import { ActionTree } from "vuex";
import AppState from "@/store/modules/App/types";
import RootState from "@/types/store/RootState";

//actions
const actions: ActionTree<AppState, RootState> = {
	ScrollProfileContainer({ state }, to = 0): void {
		const _container = document.querySelector<HTMLElement>(
			state.profileContainerClass
		);
		_container?.scrollTo({ top: to, behavior: "smooth" });
	},
};

export default actions;
