//types
import { MutationTree } from "vuex";
import CommonState from "./types";
import { CoicesShemaApps } from "@/types/server_info/ChoicesSchematic";
//mutations
const mutations: MutationTree<CommonState> = {
	targetDemoMode(state) {
		// alert('targetDemoMode Mutation')
		state.isDemoMode = !state.isDemoMode;
	},
	setNumberUnreadNotifications(state, newCount: number) {
		state.numberUnreadNotifications = newCount;
	},
	setChoicesServerInfo(state, newChoicesServerInfo: CoicesShemaApps|null) {
		state.choicesServerInfo = newChoicesServerInfo;
	},
};

export default mutations;
