import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0afdef41"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-input" }
const _hoisted_2 = { class: "search-input__input-container" }
const _hoisted_3 = { class: "search-input__close-button" }
const _hoisted_4 = ["value", "placeholder"]
const _hoisted_5 = { class: "search-input__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_search_icon = _resolveComponent("search-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.modelValue)
          ? (_openBlock(), _createBlock(_component_close_icon, {
              key: 0,
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.emit('clear-input')), ["prevent"]))
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("input", {
        type: "text",
        value: _ctx.modelValue,
        placeholder: _ctx.placeholder,
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.UpdateValue && _ctx.UpdateValue(...args)))
      }, null, 40, _hoisted_4),
      _createElementVNode("div", {
        class: _normalizeClass({loader: _ctx.showSpinner})
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_search_icon, {
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.emit('search')), ["prevent"]))
      })
    ])
  ]))
}