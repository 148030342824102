
import { defineComponent } from "vue";

export default defineComponent({
	name: "ChevronSmallLeft",
  props: {
		color: {
			type: String,
			default: (): string => {
				return 'black';
			},
		},
    width: {
			type: Number,
			default: (): number => {
				return 10;
			},
		},
    height: {
			type: Number,
			default: (): number => {
				return 17;
			},
		},
    viewBox: {
			type: String,
			default: (): string => {
				return '0 0 10 17';
			},
		},
  },
});
