//types
import { ActionTree } from "vuex";
import AuthState from "@/store/modules/Auth/types";
import RootState from "@/types/store/RootState";

import { UserData } from "@/types/user/User"
//api
import { refresh_token } from "@/api/authorization";
import router from "@/router";
//actions
const actions: ActionTree<AuthState, RootState> = {
	RefreshToken({ commit }): void {
		const token = localStorage.getItem("token");
		const refresh = localStorage.getItem("refresh_token");
		// if (token && refresh && 0) {
		if (token && refresh) {
			refresh_token(refresh)
				.then(({ data }) => {
					console.log('Замена токенов')
					localStorage.setItem("token", data.access);
					localStorage.setItem("refresh_token", data.refresh);
					commit("AuthorizeUser");
				})
				.catch(() => {
					console.log('Не получилось обновить токен, выходим из системы...')
					localStorage.removeItem("token");
					localStorage.removeItem("refresh_token");
					commit("Logout");
				});
		}
	},

	Logout({ commit }): void {
		const token = localStorage.getItem("token");
		const refresh = localStorage.getItem("refresh_token");
		if (token && refresh) {
			// localStorage.removeItem("token");
			// localStorage.removeItem("refresh_token");
			commit("Logout");
		}
		router.push("/")
	},

	SetUserMeData(ctx, { user, }): void {
		const UserData: UserData = user as UserData
		console.log('SetUserMeData')
		ctx.commit("SET_USER_DATA", UserData);
	},
	SetUserAvatar(ctx , avatar_src): void {
		// const UserData: UserData = user as UserData
		console.log('SetUserAvatar')
		ctx.commit("SET_USER_AVATAR", avatar_src);
		},

};


export default actions;
