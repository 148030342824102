
import { defineComponent } from "vue";
//types
import User from "@/types/auth/User";
//components
import SearchInput from "@/components/form-elements/inputs/SearchInput.vue";
import BaseButton from "@/components/form-elements/buttons/BaseButton.vue";

export default defineComponent({
	name: "AppHeaderMobile",
	components: {
		BaseButton,
		SearchInput,
	},
	data() {
		return {
			show: false,
		};
	},
	computed: {
		User(): User {
			return this.$store.state.auth.User;
		},
	},
	methods: {
		Toggle(): void {
			this.show = !this.show;
		},
		Hide(): void {
			if (!this.show) this.show = false;
		},
	},
});
