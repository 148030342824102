
export declare type RoleType = 'administrator' | 'client' | 'owner' | 'system_owner' | 'director' | 'head_of_sales' | 'sales_manager' | 'head_of_call_center' | 'call_center_operator' | 'photographer' | 'office_manager' | 'lawyer';

export enum RolesUser {
  "administrator" = "Администратор системы",
  "client" = "Клиент",
  "owner" = "Владелец компании",
  "system_owner" = "Владелец CRM-системы",
  "director" = "Директор офиса",
  "head_of_sales" = "Руководитель отдела продаж",
  "sales_manager" = "Менеджер по продажам",
  "head_of_call_center" = "Руководитель колл-центра",
  "call_center_operator" = "Оператор колл-центра",
  "photographer" = "Фотограф",
  "office_manager" = "Офис-менеджер",
  "lawyer" = "Юрист",
}

export enum RolesUserKeyName {
	ADMINISTRATOR = "administrator",
  CLIENT = "client",
  OWNER = "owner",
  SYSTEM_OWNER = "system_owner",
  DIRECTOR = "director",
  HEAD_OF_SALES = "head_of_sales",
  SALES_MANAGER = "sales_manager",
  HEAD_OF_CALL_CENTER = "head_of_call_center",
  CALL_CENTER_OPERATOR = "call_center_operator",
  PHOTOGRAPHER = "photographer",
  OFFICE_MANAGER = "office_manager",
  LAWYER = "lawyer",
}

// export interface RolesList{
//   [key: number]: 0,
// }

