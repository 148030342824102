import { createApp } from "vue";
import App from "./App.vue";
//router
import router from "./router";
//store
import store from "./store";
//global
import registerIcons from "@/global/GlobalIcons";
//directives
//packages
import Maska from "maska";
import Vue3TouchEvents from "vue3-touch-events";

// declare module '@bchteam/vue-jivosite';
import VueJivosite from '@bchteam/vue-jivosite'

import YmapPlugin from 'vue-yandex-maps'
const settings = {
    apiKey: "ba6df2f8-6b1e-4bbc-80d0-278accec895c", // Индивидуальный ключ API
    lang: "ru_RU", // Используемый язык
    coordorder: "latlong", // Порядок задания географических координат
    debug: false, // Режим отладки
    version: "2.1", // Версия Я.Карт
};


// import ClickOutside from "@/directives/clickoutsidecustom";
// import { vOnClickOutside } from '@vueuse/components'

//creating app
const APP = createApp(App);
registerIcons(APP);
// APP.use(store).use(router).use(Maska).use(Vue3TouchEvents).use(YmapPlugin, settings).mount("#app");
// APP.use(store).use(router).use(Maska).use(Vue3TouchEvents).directive('click-outside', ClickOutside).mount("#app");
APP.use(store);
APP.use(router);
APP.use(Maska);
APP.use(Vue3TouchEvents);
APP.use(YmapPlugin, settings);
// APP.use(VueJivosite, {
//     widgetId: 'WFh7GSLxU1'
//   });

  

APP.mount('#app');
