//types
import { GetterTree } from "vuex";
import AuthState from "@/store/modules/Auth/types";
import RootState from "@/types/store/RootState";

const getters: GetterTree<AuthState, RootState> = {
	isAuth: (state): boolean => {
		return state.isAuth;
	},
	RegistrationComplete: (state): boolean => {
		return Boolean(state.User?.id ?? false);
	},
	// RegistrationComplete: (state): boolean => {
	// 	return state.User?.registrationComplete ?? false;
	// },
};
export default getters;
