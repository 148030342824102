
import { defineComponent } from "vue";
import { OnClickOutside } from "@vueuse/components";
//components
import BaseButton from "@/components/form-elements/buttons/BaseButton.vue";
export default defineComponent({
  name: "ConfirmPopup",
  emits: ["yes", "no"],
  components: {
    OnClickOutside,
    BaseButton,
  },
  props: {
    textMessage: {
      type: String,
      default: (): string => {
        return "Вы уверены?";
      },
    },
  },
  setup(props, { emit }) {
    function Yes(): void {
      emit("yes");
    }
    function No(): void {
      emit("no");
    }

    return {
      Yes,
      No,
    };
  },
});
