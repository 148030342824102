import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
//middlewares
import Auth from "@/middlewares/Auth";
// import RegistrationIsNotCompleted from "@/middlewares/RegistrationIsNotCompleted";
// import RegistrationCompleted from "@/middlewares/RegistrationsCompleted";
// import UserIsVerified from "@/middlewares/UserIsVerified";
//options
const SITENAME = process.env.VUE_APP_NAME;
//routes
const AuthorizationComponent = () =>
	import(
		/* webpackChunkName: "auth" */ "@/views/Authorization/AuthorizationComponent.vue"
	);
const DashboardView = () =>
	import(
		/* webpackChunkName: "profile" */ "@/views/Profile/Dashboard/DashboardView.vue"
	);
const CompletingRegistration = () =>
	import(
		/* webpackChunkName: "company" */ "@/views/Company/CompletingRegistration/CompletingRegistration.vue"
	);
const CompanyManagement = () =>
	import(
		/* webpackChunkName: "management" */ "@/views/Company/CompanyManagement/CompanyManagement.vue"
	);

const MeView = () =>
	import(/* webpackChunkName: "me" */ "@/views/Profile/Me/MeView.vue");
const OrdersView = () =>
	import(/* webpackChunkName: "orders" */ "@/views/Orders/OrdersView.vue");

const OrdersItemView = () =>
	import(
		/* webpackChunkName: "order-item" */ "@/views/OrdersItem/OrdersItemView.vue"
	);

const OffersView = () =>
	import(/* webpackChunkName: "offers" */ "@/views/Offers/OffersView.vue");
const OffersItemView = () =>
	import(
		/* webpackChunkName: "offers-item" */ "@/views/OffersItem/OffersItemView.vue"
	);

const OffersItemPresentationView = () =>
	import(
		/* webpackChunkName: "offers-item-presentation" */ "@/views/OffersItemPresentation/OffersItemPresentationView.vue"
	);

const DocumentGeneratorView = () =>
	import(
		/* webpackChunkName: "document-generator" */ "@/views/DocumentGenerator/DocumentGeneratorView.vue"
	);
const BayerDocumentGeneratorView = () =>
	import(
		/* webpackChunkName: "document-generator" */ "@/views/DocumentGenerator/BayerDocumentGeneratorView.vue"
	);
const RentBayerDocumentGeneratorView = () =>
	import(
		/* webpackChunkName: "document-generator" */ "@/views/DocumentGenerator/RentBayerDocumentGeneratorView.vue"
	);
	

const CallsHistoryView = () =>
	import(/* webpackChunkName: "orders" */ "@/views/CallsHistory/CallsHistoryView.vue");

const AlertsView = () =>
	import(/* webpackChunkName: "alerts" */ "@/views/Alerts/AlertsView.vue");

// const DocumentGeneratorViewComp = () =>
// 	import(
// 		/* webpackChunkName: "document-generator" */ "@/views/DocumentGenerator/DocumentGeneratorViewComp.vue"
// 	);

const TasksView = () =>
	import(/* webpackChunkName: "tasks" */ "@/views/Tasks/TasksView.vue");

const RealEstateReceivedDataView = () =>
	import(/* webpackChunkName: "real-estate-received-data" */ "@/views/RealEstateReceivedData/RealEstateReceivedDataView.vue");

const MapSearch = () => import(/* webpackChunkName: "map-search" */ "@/components/elements/Offers/modal_windows/MapSearch.vue");

const NotFound = () => import("@/views/Errors/NotFound.vue");
const NoAccess = () => import("@/views/Errors/NoAccess.vue");

const routes: Array<RouteRecordRaw> = [
	// {
	// 	path: "/",
	// 	name: "Authorization",
	// 	component: AuthorizationComponent,
	// 	meta: {
	// 		title: "Войти в CRM",
	// 	},
	// 	props: true
	// },
	{
		path: "/",
		name: "Default",
		component: TasksView,  // DashboardView,
		meta: {
			title: "Список задач",
			middlewares: [Auth],
			// middlewares: [Auth, UserIsVerified],
			layout: "profile-layout",
		},
	},
	{
		path: "/auth",
		name: "Authorization",
		component: AuthorizationComponent,
		meta: {
			title: "Войти в CRM",
		},
		props: true,
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		component: DashboardView,
		meta: {
			title: "Приборная панель",
			middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			layout: "profile-layout",
		},
	},
	{
		path: "/me",
		name: "Me",
		component: MeView,
		meta: {
			title: "Личный кабинет",
			middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			layout: "profile-layout",
		},
	},
	{
		path: "/orders",
		name: "Orders",
		component: OrdersView,
		meta: {
			title: "Заявки",
			middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			layout: "profile-layout",
		},
	},
	{
		path: "/orders/:id",
		name: "OrderItem",
		props: true,
		component: OrdersItemView,
		meta: {
			title: "Заявка",
			middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			// layout: "order-item-layout",
			layout: "profile-layout",
		},
	},
	{
		path: "/offers",
		name: "Offers",
		component: OffersView,
		meta: {
			title: "Объекты недвижимости",
			middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			layout: "profile-layout",
		},
	},
	{
		path: "/offers/:id",
		name: "OfferItem",
		props: true,
		component: OffersItemView,
		meta: {
			title: "Объект недвижимости",
			middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			// layout: "order-item-layout",
			layout: "profile-layout",
		},
	},
	{
		path: "/offers/:id/presentation/:encodedOptions",
		name: "OffersItemPresentation",
		props: true,
		component: OffersItemPresentationView,
		meta: {
			title: "Презентация объекта недвижимости",
			// middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			// layout: "order-item-layout",
			// layout: "empty-layout",
			layout: "presentation-offer-layout",
		},
	},
	// http://crm.loft.test/create-document/2680
	{
		path: "/create-document/:id",
		name: "CreateDocument",
		// props: true,
		component: DocumentGeneratorView,
		meta: {
			title: "Генерация документа",
			// middlewares: [Auth],
			// middlewares: [Auth, UserIsVerified],
			// layout: "order-item-layout",
			// layout: "default-layout",
			layout: "empty-layout",
			// layout: "profile-layout",
		},
	},
	{
		path: "/create-contract/seller",
		name: "CreateSellerContract",
		props: true,
		component: DocumentGeneratorView,
		meta: {
			title: "Договор с продавцом",
			// middlewares: [Auth],
			// middlewares: [Auth, UserIsVerified],
			// layout: "order-item-layout",
			// layout: "default-layout",
			layout: "empty-layout",
			// layout: "profile-layout",
		},
	},
	{
		path: "/create-contract/bayer",
		name: "CreateBayerContract",
		props: true,
		component: BayerDocumentGeneratorView,
		meta: {
			title: "Договор с покупателем",
			// middlewares: [Auth],
			// middlewares: [Auth, UserIsVerified],
			// layout: "order-item-layout",
			// layout: "default-layout",
			layout: "empty-layout",
			// layout: "profile-layout",
		},
	},
	{
		path: "/create-contract/rent-bayer",
		name: "CreateRentBayerContract",
		props: true,
		component: RentBayerDocumentGeneratorView,
		meta: {
			title: "Договор аренды с покупателем",
			// middlewares: [Auth],
			// middlewares: [Auth, UserIsVerified],
			// layout: "order-item-layout",
			// layout: "default-layout",
			layout: "empty-layout",
			// layout: "profile-layout",
		},
	},
	//
	{
		path: "/company/management",
		name: "Company",
		component: CompanyManagement,
		meta: {
			title: "Управление компанией",
			middlewares: [Auth, ],
			// middlewares: [Auth, RegistrationCompleted],
			layout: "profile-layout",
		},
	},
	{
		path: "/company/complete-registration",
		name: "CompleteRegistration",
		component: CompletingRegistration,
		meta: {
			title: "Закончите регистрацию",
			//middlewares: [ RegistrationIsNotCompleted],
		},
	},
	{
		path: "/calls",
		name: "Calls",
		component: CallsHistoryView,
		meta: {
			title: "История звонков",
			middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			layout: "profile-layout",
		},
	},
	{
		path: "/alerts",
		name: "Alerts",
		component: AlertsView,
		meta: {
			title: "Оповещения",
			middlewares: [Auth],
			// middlewares: [Auth, UserIsVerified],
			layout: "profile-layout",
		},
	},
	{
		path: "/maps",
		name: "Maps",
		component: MapSearch,
		meta: {
			title: "Карта",
			middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			layout: "profile-layout",
		},
	},
	{
		path: "/tasks",
		name: "Tasks",
		component: TasksView,
		meta: {
			title: "Список задач",
			middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			layout: "profile-layout",
		},
	},
	{
		path: "/parser",
		name: "parser",
		component: RealEstateReceivedDataView,
		meta: {
			title: "Парсер",
			middlewares: [Auth, ],
			// middlewares: [Auth, UserIsVerified],
			layout: "profile-layout",
		},
	},

	//errors
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
	{
		path: "/404",
		component: NotFound,
		meta: {
			title: "Not Found",
		},
	},
	{
		path: "/403",
		component: NoAccess,
		name: "NoAccess",
		meta: {
			title: "No access",
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	//Установка заголовка на страницу
	document.title = to.meta.title ?? SITENAME;
	if (to.meta.middlewares) {
		let AllMiddlewarePassed = true;

		to.meta.middlewares.map(async (middleware) => {
			if (!AllMiddlewarePassed) return;
			const valid = await middleware(router);
			if (!valid) AllMiddlewarePassed = false;
		});
		if (AllMiddlewarePassed) return next();
	} else {
		next();
	}
});
export default router;
