import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-89206808"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirm-popup" }
const _hoisted_2 = { class: "confirm-popup__title" }
const _hoisted_3 = { class: "confirm-popup__buttons" }
const _hoisted_4 = { class: "confirm-popup__buttons__yes" }
const _hoisted_5 = { class: "confirm-popup__buttons__no" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_OnClickOutside = _resolveComponent("OnClickOutside")!

  return (_openBlock(), _createBlock(_component_OnClickOutside, { onTrigger: _ctx.No }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.textMessage), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_base_button, {
              class: "confirm",
              label: "Да",
              onClick: _ctx.Yes
            }, null, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_base_button, {
              class: "confirm",
              label: "Нет",
              onClick: _ctx.No
            }, null, 8, ["onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onTrigger"]))
}