//types
import AppState from "@/store/modules/App/types";
//store
import getters from "@/store/modules/App/getters";
import mutations from "@/store/modules/App/mutations";
import actions from "@/store/modules/App/actions";
//state
const state: AppState = {
	profileContainerClass: ".profile-layout__main__content",
	isDemoMode: false,
};
export default {
	state,
	mutations,
	getters,
	actions,
};
