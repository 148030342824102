
import { computed, defineComponent, onBeforeMount, onMounted, onUpdated, ref } from "vue";
//components
import AppLoading from "@/components/loaders/AppLoading.vue";
import AppSidebar from "@/components/includes/AppSidebar.vue";
import AppHeader from "@/components/includes/AppHeader.vue";
import AppHeaderMobile from "@/components/includes/AppHeaderMobile.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import { GetChoicesSchematicServerInfo } from "@/helpers/SchematicServerInfo";

export default defineComponent({
  name: "PresentationOfferLayout",
  components: {
    AppSidebar,
    AppLoading,
    AppHeader,
    AppHeaderMobile,
  },

  setup() {
    const router = useRouter();

    // const id = ref<string | string[]>(router.currentRoute.value.params.id);
    const id = computed<string | string[]>(() => {
      // if (router.currentRoute.value.meta.title) {
      // 	return router.currentRoute.value.meta.title;
      // }
      return router.currentRoute.value.params.id;
    });

    const computedTitleMeta = computed<string>(() => {
      if (router.currentRoute.value.meta.title) {
        return router.currentRoute.value.meta.title;
      }
      return "";
    });

    const componentIsCompany = computed<boolean>(() => {
      if (router.currentRoute.value.name === "Company") {
        return true;
      }
      return false;
    });

    const store = useStore();


    const loading = ref<boolean>(false);
    const scrollHeight = ref<number>(0);
    const showSidebar = ref<boolean>(false);


    //Проскроллить окно вверх
    function ScrollToTop(): void {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    //Сохранение позиции скролла
    function SaveScroll(): void {
      scrollHeight.value = window.scrollY;
    }


    onBeforeMount(() => {
      console.debug("onBeforeMount ProfileLayout");
    });

    onMounted(() => {
      // GetChoicesSchematicServerInfo(store);
      // loading.value = false
    });

    onUpdated(() => {
      console.debug("onUpdated ProfileLayout");
      // window.addEventListener("scroll", SaveScroll);
    });

    return {
      loading,
      scrollHeight,
      showSidebar,
      store,
      ScrollToTop,
      SaveScroll,

      computedTitleMeta,
      componentIsCompany,
      id,
    };
  },
});
