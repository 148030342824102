
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { UserData } from "@/types/user/User";
import { RolesUser, RoleType } from "@/types/user/UserRoles";
import router from "@/router";
// import { get_profile_info } from "@/api/user";
import UserAvatar from "@/components/elements/UserAvatar/UserAvatar.vue";
import SearchGlobalInput from "@/components/form-elements/inputs/SearchGlobalInput.vue";

export default defineComponent({
	name: "AppHeader",
	components: {
		UserAvatar,
		SearchGlobalInput,
	},

	setup() {
		const store = useStore();

		const User = computed<UserData>(() => {
			return store.state.auth.User;
		});

		const srcAvatar = computed<string>(() => {
			if (store.state.auth.User.avatar) {
				return store.state.auth.User.avatar;
			}
			return "";
		});


		const rolesToString = computed<string>(() => {
			const rolesRU: string[] = [];
			//User.value.roles.forEach(function (item, index, array) {
			User.value.roles.forEach(function (item) {
				rolesRU.push(RolesUser[item as RoleType]);
			});
			return rolesRU.join(", ");
		});

		function clickAvatar(): void {
			router.push({ path: "/me" });
		}

		function clickLogout(): void {
			store.dispatch("auth/Logout");
		}

		return {
			User,
			rolesToString,
			srcAvatar,
			clickAvatar,
			clickLogout,
			//
		};
	},
});
