
import { defineComponent } from "vue";
//components
import LayoutBase from "@/layouts/LayoutBase.vue";
import ModalWindow from "@/components/modal_windows/modal-window.vue"
// import {ref} from 'vue'
// import { nextTick } from 'vue'

export default defineComponent({
	name: "App",
	components: {
    ModalWindow,
		LayoutBase,

	},
	mounted() {
		// this.$store.dispatch("auth/RefreshToken");
    //this.$refs.modal
    // console.log(this.$refs.modalPopup.open())
	},
  // setup() {
  //   const popupTriggers = ref({
  //     buttonTrigger: false,
  //     timedTrigger: false,

  //   })
  //   return {
  //     // ModalWindow,
  //     popupTriggers
  //   }
  // },
  methods: {
    afterLeave(): void{
      // this.$store.commit("popup/closeModal");
      // Событие после исчезновения объекта
      console.log('Очистка popup')
      this.$store.commit("popup/setMessage", "");
    },
    // showModal(): void {

    //   const a = this.$store.state;
    //   //alert(a)
    //   console.log('state',a)
    //   alert(this.$store.state.popup.modalVisible)

    //   this.$store.commit("popup/targetModal");

    //   // this.$store.commit("auth/showModal", ModalWindow);
    //   //console.log(this.$refs.modal)
    //   // this.$nextTick(
    //   //   function () {
    //   //     this.$refs.modalPopup.open();
    //   //   }.bind(this)
    //   // );
    //   // this.$refs.modalPopup.open()

    //     // if (popupResult){
    //     //   alert("Okkk")
    //     // }

    // }

  },
});
