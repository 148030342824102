/* eslint-disable */
import { camelCase } from "lodash-es";

const requireModule = require.context(
	".",
	true,
	/^(?!.*(actions|mutations|getters|types|helpers|index)).*\.ts$/
);

const modules: any = {};

requireModule.keys().forEach((fileName) => {
	if (/\.unit\.ts$/.test(fileName)) return;

	modules[camelCase(fileName.split("/")[1].replace(/(\.\/|\.js)/g, ""))] = {
		namespaced: true,
		...requireModule(fileName).default,
	};
});

export default modules;
