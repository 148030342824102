import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-801d6a68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search__input" }
const _hoisted_2 = { class: "search__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_SearchGlobalModal = _resolveComponent("SearchGlobalModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SearchInput, {
        placeholder: "#объекта, #клиента, улица, телефон",
        modelValue: _ctx.searchString,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchString) = $event)),
        onSearch: _ctx.searchAll,
        onClearInput: _ctx.ResetSearch,
        onClick: _ctx.ClickSearchInput,
        "show-spinner": _ctx.loading
      }, null, 8, ["modelValue", "onSearch", "onClearInput", "onClick", "show-spinner"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showSearchBlock && !_ctx.loading)
        ? (_openBlock(), _createBlock(_component_SearchGlobalModal, {
            key: 0,
            "result-search-all": _ctx.resultSearchAll,
            "search-string": _ctx.searchString,
            onClose: _ctx.CloseSearchGlobalModal,
            loading: false
          }, null, 8, ["result-search-all", "search-string", "onClose"]))
        : _createCommentVNode("", true)
    ])
  ]))
}