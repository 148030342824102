import api from "@/instances/api";
//types
import { AxiosResponse } from "axios";
import RegisterCompanyData from "@/types/auth/RegisterCompanyData";
//urls
import {
	AUTHORIZE_URL,
	REFRESH_TOKEN_URL,
	REGISTER_COMPANY_URL,
	REGISTER_ENTREPRENEUR_URL,
	REGISTER_AGENT_URL,
	SEND_CODE_URL,
	SEND_OGRN13_URL,
	SEND_OGRN15_URL,
	SEND_LOGIN_URL,
	UPDATE_CODE_URL,
  SEND_OGRN_URL,
} from "@/api/authorization/urls";

// TODO: При интеграции с бэком переписать на POST
export function login_request(
	identity_name: string,
	identity_value: string
): Promise<AxiosResponse> {
  console.log('login_request')

  return api.post(SEND_LOGIN_URL, { identity_name, identity_value },
	);

	// return api.get(SEND_LOGIN_URL, {
	// 	params: { identity_name, identity_value },
	// });
}
// Отправка проверочного кода
export function send_code_verification(
  identity_name: string,  // "email" | phone | ??telegram
	identity_value: string,
): Promise<AxiosResponse> {
	// return axios.post(
  return api.post(
    SEND_CODE_URL,
		{identity_name, identity_value},
	  );
  }

// TODO: При интеграции с бэком переписать на POST
export function confirm_code(
	identity_name = "phone",
	identity_value: string,
	confirmation_code: string
): Promise<AxiosResponse> {
  return api.post(
    SEND_CODE_URL,
    {
			identity_name,
			identity_value,
			confirmation_code,
	  },
  );

	// return api.get(SEND_CODE_URL, {
	// 	params: {
	// 		identity_name,
	// 		identity_value,
	// 		confirmation_code,
	// 	},
	// });
}
// TODO: При интеграции с бэком переписать на POST
export function update_code(): Promise<AxiosResponse> {
	return api.post(UPDATE_CODE_URL);
}

// TODO: На бэке определяется 13 или 15 знаков в ОГРН/ОГРНИП
export function ogrn_request(
	identity_name = "ogrn",
	identity_value: string
): Promise<AxiosResponse> {
  // const URL_G = 'http://loft.test'+SEND_OGRN13_URL
	// return axios.get(URL_G, {
	return api.get(SEND_OGRN_URL, {
		params: {
			identity_name,
			identity_value,
		},
	});
}

// TODO: При интеграции с бэком переписать на POST
// TODO: При переводе на реальный бэк останется один запрос
// TODO: Сечас два (на 13 и 15 знаков) в целях естирования
export function ogrn_request13(
	identity_name = "ogrn",
	identity_value: string
): Promise<AxiosResponse> {
  // const URL_G = 'http://loft.test'+SEND_OGRN13_URL
	// return axios.get(URL_G, {
	return api.get(SEND_OGRN13_URL, {
		params: {
			identity_name,
			identity_value,
		},
	});
}

export function ogrn_request15(
	identity_name = "ogrn",
	identity_value: string
): Promise<AxiosResponse> {
	return api.get(SEND_OGRN15_URL, {
		params: {
			identity_name,
			identity_value
		},
	});
}
// TODO: При интеграции с бэком переписать на POST
// TODO: При переводе на реальный бэк останется один запрос
// TODO: Сечас два (на 13 и 15 знаков) в целях естирования
export function register_company(
	data: RegisterCompanyData
): Promise<AxiosResponse> {
	return api.get(REGISTER_COMPANY_URL, { params: data });
}

export function register_entrepreneur(
	data: RegisterCompanyData
): Promise<AxiosResponse> {
	return api.get(REGISTER_ENTREPRENEUR_URL, { params: data });
}

export function register_agent(
	data: RegisterCompanyData
): Promise<AxiosResponse> {
	return api.get(REGISTER_AGENT_URL, { params: data });
}


// TODO: При интеграции с бэком переписать на POST
export function authorize_user(
	//login: string,
	identity_value: string,
	identity_name = "email",
  confirmation_code: string,
): Promise<AxiosResponse> {
	return api.post(
    AUTHORIZE_URL,
		{identity_name, identity_value, confirmation_code},
	);

  // return api.get(AUTHORIZE_URL, {
	// 	params: {
	// 		login,
	// 		identity_value,
	// 		identity_name,
	// 	},
	// });
}
// TODO: При интеграции с бэком переписать на POST
export function refresh_token(refresh: string): Promise<AxiosResponse> {
  console.log('отправка REFRESH_TOKEN')
	return api.post(REFRESH_TOKEN_URL, { refresh });
  //return api.get(REFRESH_TOKEN_URL, { params: { refresh } });
}
