//types
import PopupState from "@/store/modules/Popup/types";
//store
import getters from "@/store/modules/Popup/getters";
import mutations from "@/store/modules/Popup/mutations";
import actions from "@/store/modules/Popup/actions";
import { messageOptions } from "@/types/common/message/MessageOptions";
//state
const state: PopupState = {
  PopUp: false,
  modalVisible: false,
  modalComponent: null,
  message: "333",
  type: messageOptions.INFO,
};
export default {
	state,
	mutations,
	getters,
	actions,
};
