/* eslint-disable */
export default function registerIcons(vm: any): void {
	try {
		// Require base component context
		const requireComponent = require.context(
			"../svg-icons/",
			true,
			/[\w-]+\.vue$/
		);

		requireComponent.keys().forEach((filePath) => {
			// Get component config
			const componentConfig = requireComponent(filePath);
			// Get filename from the filePath
			const fileName = filePath.split("/").slice(-1)[0];
			// Remove file extension and convert component name to pascal case
			const componentName = fileName.replace(/\.\w+$/, "");
			// Register component globally
			vm.component(
				componentName,
				componentConfig.default || componentConfig
			);
		});
	} catch {
		return;
	}
};
