import axios, { AxiosRequestConfig } from "axios";

// import { refresh_token } from "@/api/authorization";
import { REFRESH_TOKEN_URL } from "@/api/authorization/urls";
import router from "@/router";

const baseURL = process.env.VUE_APP_API_URL;
console.info("VUE_APP_API_URL baseURL", baseURL);


const config: AxiosRequestConfig = {
  timeout: 30000,
  baseURL: process.env.VUE_APP_API_URL,
  // baseURL: 'http://loft.test',
  
};

config.withCredentials = true;

const api = axios.create(config);


api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    console.log('Подброс токена Bearer')
    // console.log(process.env)
    // console.log(baseURL)
    const token = localStorage.getItem("token");
    if (token) {
      console.log('Токен найден в localStorage = ', token)
      if (!config.headers) {
        console.error('None HEADERS')
        config.headers = {};
      }
      //config.headers = {};
      config.headers.Authorization = `Bearer ${token}`;
      // console.error('config', config.headers)
    }
    return config;
  }
  // async (error) => {
  //   console.log('Error: Подброс токена Bearer')
  //   // const http_status = error.response.status;
  //   // console.error("MyError response status: " + http_status);

  // 	return Promise.reject(error);
  // }
);

//start response
api.interceptors.response.use(
  async (config) => { return config },
  async (error) => {
    //return Promise.reject(error.response.data.error);
    //return {error}
    //return Promise.reject(error);
    console.log('ERRRRRRRRRRRRRORORORR')
    console.info('error',error)
    // Срабатывает на ошибки от бэка
    console.error("MyError: api response triger");
    // console.log(error);


    // Если токен авторизации просрочек, и получаем 403
    // if (http_status === 403) {
    //   console.log(http_status)
    //   console.warn('Токен просрочен!!!')
    // }
    if (error.response) {
      // Запрос сделан, и сервер ответил
      const http_status = error.response.status;
      console.log(http_status);

      // console.info('error.response',error.response)

      if (http_status === 401) {

        console.warn('Нужно обновить токен!!!')
        // Обновление access токена, через refresh токен
        if ("token_not_valid" == error.response.data["code"]) {
          console.warn('access токен: ' + error.response.data["code"]);
          console.log("Обновляем токен");

          //const refresh = localStorage.getItem("refresh");
          const refresh = localStorage.getItem("refresh_token");

          if (refresh) {
            console.log('config', config)
            return axios.post(config['baseURL'] + REFRESH_TOKEN_URL, { refresh })
              .then(({ data }) => {
                console.log('Замена токенов')
                localStorage.setItem("token", data.access);
                localStorage.setItem("refresh_token", data.refresh);
                console.info(data)
                error.config.headers.Authorization = `Bearer ${data.access}`;
                console.info("<---Повтор запроса с новым access токеном--->");
                return api.request(error.config); // Повтор всего цикла, !!! Возможна рекурсия

                //commit("AuthorizeUser");
              }).catch((error) => {
                // refresh токен не действительный, перекидываем на страницу авторизации
                console.log('Не получилось обновить токен, выходим из системы...')
                localStorage.removeItem("token");
                localStorage.removeItem("refresh_token");
                if ("token_not_valid" == error.response.data["code"]) {
                  console.warn('refresh токен: ' + error.response.data["code"]);
                  console.log("refresh токен УСТАРЕЛ");
                  router.push({ name: "Authorization" });  // Переход на страницу авторизации
                }
                // commit("Logout");
              });
          }
          else{
            console.debug('NONE REFRESH token 1!')
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            router.push({ name: "Authorization" });  // Переход на страницу авторизации
            // router.push("/")
          }

          // return axios
          //   .post(
          //     `${store.getters.getServerUrl}${URLS["refresh"]}`,
          //     {refresh: refresh}
          //   )

        }
        else{
          console.debug('NONE REFRESH token 2!')
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
        }
      }
      else {
        // Возврат всех остальных ошибок, если сервер ответил на запрос
        return Promise.reject(error);
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    } else if (error.request) {
      // Запрос был сделан, но ответа получено не было
      console.error('Запрос был сделан, но ответа получено не было')
      console.log(error.request);
    } else {
      // Что-то произошло при настройке запроса, что вызвало ошибку
      console.info('Что-то произошло при настройке запроса, что вызвало ошибку')
      console.log('Error', error.message);
    }




  }
)



const cancelToken = axios.CancelToken;
export const apiSource = cancelToken.source();

export default api;
