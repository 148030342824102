//types
import AppState from "@/store/modules/App/types";
//store
import getters from "@/store/modules/Common/getters";
import mutations from "@/store/modules/Common/mutations";
import actions from "@/store/modules/Common/actions";
import CommonState from "./types";
//state
const state: CommonState = {
	isDemoMode: false,
	numberUnreadNotifications: 0,
	choicesServerInfo: null,
};
export default {
	state,
	mutations,
	getters,
	actions,
};
