//types
import { ActionTree } from "vuex";
import RootState from "@/types/store/RootState";
import CommonState from "./types";
// import setMessage from "@/store/modules/Popup/mutations"

//actions
const actions: ActionTree<CommonState, RootState> = {
  targetDemoAction(ctx): void {
    // console.log('targetDemoAction')
    ctx.commit('targetDemoMode')
    // ctx.commit('setTypeAlert', type)
    // ctx.commit('showModal', 'текст мутации')
	},
  setNumberUnreadNotificationsAction(ctx , newCount): void {
		console.log('setNumberUnreadNotificationsAction')
		ctx.commit("setNumberUnreadNotifications", newCount);
	},
  setChoicesServerInfoAction(ctx , newChoicesServerInfo): void {
		console.log('setChoicesServerInfoAction')
		ctx.commit("setChoicesServerInfo", newChoicesServerInfo);
	},
};

export default actions;
