import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da14df54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = { class: "pagination__pages" }
const _hoisted_3 = {
  key: 1,
  class: "pagination__last-page"
}
const _hoisted_4 = { class: "pagination__pages" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 2,
  class: "pagination__last-page"
}
const _hoisted_7 = { class: "pagination__pages" }
const _hoisted_8 = { class: "pagination-quantity" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_normal_left = _resolveComponent("chevron-normal-left")!
  const _component_chevron_small_right = _resolveComponent("chevron-small-right")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentPage > 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "pagination__back-page",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPage(_ctx.currentPage - 1)))
        }, [
          _createVNode(_component_chevron_normal_left, { class: "svg-icon" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (
					(_ctx.currentPage == _ctx.lastPageNumber && _ctx.lastPageNumber !== 1) ||
					(!_ctx.pagesShow.includes(1) && !_ctx.pagesShow.includes(2))
				)
        ? (_openBlock(), _createElementBlock("div", {
            class: "pagination__pages__item",
            key: _ctx.pages[0],
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPage(_ctx.pages[0])))
          }, _toDisplayString(_ctx.pages[0]), 1))
        : _createCommentVNode("", true)
    ]),
    (
				(_ctx.currentPage == _ctx.lastPageNumber && _ctx.lastPageNumber !== 1) ||
				(!_ctx.pagesShow.includes(1) && !_ctx.pagesShow.includes(2))
			)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, " ... "))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pagesShow, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["pagination__pages__item", { current: _ctx.currentPage === item }]),
          key: item,
          onClick: ($event: any) => (_ctx.setPage(item))
        }, _toDisplayString(item), 11, _hoisted_5))
      }), 128))
    ]),
    (!_ctx.pagesShow.includes(_ctx.lastPageNumber) && _ctx.countItems > _ctx.showPages)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, " ... "))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.showEndPage)
        ? (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["pagination__pages__item", { current: _ctx.currentPage === _ctx.lastPageNumber }]),
            key: _ctx.lastPageNumber,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPage(_ctx.lastPageNumber)))
          }, _toDisplayString(_ctx.lastPageNumber), 3))
        : _createCommentVNode("", true)
    ]),
    (_ctx.currentPage !== _ctx.lastPageNumber && _ctx.countItems > _ctx.showPages)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "pagination__next-page",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setPage(_ctx.currentPage + 1)))
        }, [
          _createVNode(_component_chevron_small_right, { class: "svg-icon" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_8, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quantityItems, (count) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass({'selected-quantity': _ctx.showPages == count}),
            onClick: ($event: any) => (_ctx.setShowOnPage(count))
          }, _toDisplayString(count), 11, _hoisted_9))
        }), 256))
      ])
    ])
  ]))
}