
import { computed, defineComponent, onBeforeMount, onMounted, onUpdated, ref } from "vue";
//api
import { get_profile_info } from "@/api/user";
//components
import AppLoading from "@/components/loaders/AppLoading.vue";
import AppSidebar from "@/components/includes/AppSidebar.vue";
import AppHeader from "@/components/includes/AppHeader.vue";
import AppHeaderMobile from "@/components/includes/AppHeaderMobile.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { UserData } from "@/types/user/User";
import { get_choices_schematic_server_info } from "@/api/server_info";
import { CoicesShemaApps } from "@/types/server_info/ChoicesSchematic";

export default defineComponent({
	name: "OrderItemLayout",
	components: {
		AppSidebar,
		AppLoading,
		AppHeader,
		AppHeaderMobile,
	},

	setup() {
		const router = useRouter();

		const computedTitleMeta = computed<string>(() => {
			if (router.currentRoute.value.meta.title) {
				return router.currentRoute.value.meta.title;
			}
			return "";
		});

		// const id = computed(() => {route.value.params.id)

		const id = ref<string|string[]>(router.currentRoute.value.params.id);

		// const id = computed<string>(() => {

		// 	st.value  = router.currentRoute.value.params.id

		// 	const id_rt: string = router.params.id
			
		// 	if (id_r) {
		// 		return id_r;
		// 	}
		// 	return "";
		// });

		// const computedIdMeta = computed<string|number>(() => {

		// 	// const ttt = router.value.params.id

		// 	// const ttt = route.params.id
		// 	if (router.currentRoute.value.meta.id) {
		// 		return router.currentRoute.value.meta.id;
		// 	}
		// 	return "";
		// });

		const componentIsCompany = computed<boolean>(() => {
			if (router.currentRoute.value.name === "Company") {
				return true;
			}
			return false;
		});

		const store = useStore();

		const loading = ref<boolean>(true);
		const scrollHeight = ref<number>(0);
		const showSidebar = ref<boolean>(true);

		//Получить информацию о пользователе
		function GetProfileInfo(): void {
			console.log("Получить информацию о пользователе");
			loading.value = true;
			get_profile_info().then(({ data }) => {
				console.log("u data", data);
				const UserData: UserData = data.User
				store.commit("auth/SET_USER_DATA", UserData);
				loading.value = false;
			});
		}

		//Проскроллить окно вверх
		function ScrollToTop(): void {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}

		//Сохранение позиции скролла
		function SaveScroll(): void {
			scrollHeight.value = window.scrollY;
		}

		onBeforeMount(() => {
			console.debug('onBeforeMount ProfileLayout')
		});

		onMounted(() => {
			console.debug('onMounted ProfileLayout')
			GetProfileInfo();
			window.addEventListener("scroll", SaveScroll);
		});

		onUpdated(() => {
			console.debug('onUpdated ProfileLayout')
			// GetProfileInfo();
			// window.addEventListener("scroll", SaveScroll);
		});

		return {
			loading,
			scrollHeight,
			showSidebar,
			store,

			GetProfileInfo,
			ScrollToTop,
			SaveScroll,

			computedTitleMeta,
			// computedIdMeta,
			componentIsCompany,

			id,
			// router,
		};
	},
});
