
import { defineComponent } from "vue";

export default defineComponent({
	name: "BaseButton",
	props: {
		label: {
			type: String,
			default: (): string => {
				return "";
			},
		},
		titleHover: {
			type: String,
			default: (): string => {
				return "";
			},
		},
	},
});
