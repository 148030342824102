
import { defineComponent } from "vue";
//components
import DefaultLayout from "@/layouts/layoutTypes/DefaultLayout.vue";
import ProfileLayout from "@/layouts/layoutTypes/ProfileLayout.vue";
import OrderItemLayout from "@/layouts/layoutTypes/OrderItemLayout.vue"
import EmptyLayout from "@/layouts/layoutTypes/EmptyLayout.vue"
import PresentationOfferLayout from "@/layouts/layoutTypes/PresentationOfferLayout.vue";

export default defineComponent({
	name: "LayoutBase",
	components: {
		DefaultLayout,
		ProfileLayout,
		OrderItemLayout,
		EmptyLayout,
		PresentationOfferLayout,
	},
	computed: {
		layout(): string {
			return this.$route.meta.layout ?? "default-layout";
		},
	},
});
