import api from "@/instances/api";
import ID from "@/types/common/Id";

import { AxiosResponse } from "axios";

import { FilterRealtyObjectOrdersPayload } from "@/types/orders/PayloadOpts";
import { OFFERS_URL } from "../offers/urls";
import { SEARCH_ALL_URL, SEARCH_OFFERS_URL, SEARCH_ORDERS_URL } from "./urls";
import { SearchAllPayload } from "@/types/search/Offer";

// Список предложений
export function get_offers(
	opts?: FilterRealtyObjectOrdersPayload
): Promise<AxiosResponse> {
	let params_data = {};
	if (opts) {
		const house_type = opts.house_type;
		//   const number_rooms = opts.number_rooms
		const object_type = opts.object_type;
		//   const price_from = opts.price_from
		//   const price_to = opts.price_to
		//   const total_area_from = opts.total_area_from
		//   const total_area_to = opts.total_area_to
		//   const year_from = opts.year_from
		//   const year_to = opts.year_to
		//
		const client_order__service_type = opts.client_order__service_type;
		const client_order__stage = opts.client_order__stage;

		const page = opts.page;
		const page_size = opts.page_size;
		const search = opts.search;

		const client_order__status__in = opts.client_order__status;
		const payment_type = opts.payment_type; //'{cash,mortgage}' //opts.payment_type
		params_data = {
			payment_type,
			client_order__status__in,
			house_type,
			// number_rooms,
			object_type,
			// price_from, price_to,
			// total_area_from, total_area_to,
			// year_from, year_to,
			//
			client_order__service_type,
			client_order__stage,
			search,
			page,
			page_size,
		};
	}

	return api.get(
		OFFERS_URL,
		{ params: opts }
		// data,
	);
}


// Глобальный поиск
export function search_global(
	opts?: SearchAllPayload
): Promise<AxiosResponse> {
	return api.get(
		SEARCH_ALL_URL,
		{ params: opts }
		// data,
	);
}

// Поиск офферов
export function search_offers(
	opts?: SearchAllPayload
): Promise<AxiosResponse> {
	return api.get(
		SEARCH_OFFERS_URL,
		{ params: opts }
		// data,
	);
}

// Поиск заявок
export function search_orders(
	opts?: SearchAllPayload
): Promise<AxiosResponse> {
	return api.get(
		SEARCH_ORDERS_URL,
		{ params: opts }
		// data,
	);
}



