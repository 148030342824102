import AuthState from "@/store/modules/Auth/types";
import getters from "@/store/modules/Auth/getters";
import actions from "@/store/modules/Auth/actions";
import mutations from "@/store/modules/Auth/mutations";

const state: AuthState = {
	isAuth: false,
	User: null,
};
export default {
	state,
	getters,
	actions,
	mutations,
};
