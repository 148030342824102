//types
import { MutationTree } from "vuex";
import PopupState from "@/store/modules/Popup/types";
import { messageOptions } from "@/types/common/message/MessageOptions";
//mutations
const mutations: MutationTree<PopupState> = {
  // NEW
  showModal(state, message) {
    state.modalVisible = true;
    //alert('showModal Mutation')
    // state.modalComponent = componentName;
    // componentName.open()
  },
  setTypeAlert(state, type = messageOptions.INFO){
    state.type = type;
  },
  modalSetAndShow(state, {message, type = messageOptions.INFO}) {
    //Получает текст сообщени на вход и отображаеть модальное окно
    state.modalVisible = true;
    state.message = message;
    state.type = type;
    //alert('showModal Mutation')
    // state.modalComponent = componentName;
    // componentName.open()
  },
  hideModal(state) {
    //alert('hideModal Mutation')
    state.modalVisible = false;
  },
  targetModal(state){
    //alert('targetModal Mutation')
    state.modalVisible = !state.modalVisible;
  },
  closeModal(state){
    console.info('closeModal Mutation')
    state.modalVisible = false;
  },
  setMessage(state, text:string){
    console.info('closeModal Mutation')
    state.message = text;
  }
};

export default mutations;
