import { CompanyEmployeeUser } from "@/types/company/CompanyEmployee";

// Получить фамилию и имя сотрудника
export function getEmployeeFI(employee: CompanyEmployeeUser | undefined | null): string {
    const manager = employee;
    if (!manager) {
        return "Отсутствует";
    }
    const last_name = manager.user.last_name;
    const first_name = manager.user.first_name;

    if (last_name && first_name) {
        return `${last_name} ${first_name}`;
    } else if (!last_name && first_name) {
        return `${first_name}`;
    } else if (last_name && !first_name) {
        return `${last_name}`;
    }
    return "Не известно";
};