import api from "@/instances/api";
import { AxiosResponse } from "axios";
import { CHOICES_SCHEMATIC_SERVER_INFO_URL } from "./urls";


// Получить схему choices
export function get_choices_schematic_server_info(): Promise<AxiosResponse> {

	return api.get(
		CHOICES_SCHEMATIC_SERVER_INFO_URL,
	);
}
