import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26bc1eaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-settings-readonly__avatar-div mb-input" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_icon_no_size = _resolveComponent("user-icon-no-size")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["profile-settings-readonly__avatar", { 'color-empty-avatar': !_ctx.srcAvatar }])
    }, [
      (_ctx.srcAvatar)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "profile-settings-readonly__img w-100",
            src: _ctx.srcAvatar
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createBlock(_component_user_icon_no_size, { key: 1 }))
    ], 2)
  ]))
}