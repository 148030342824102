
import { computed, defineComponent } from "vue";
export default defineComponent({
	name: "PaginationItemArray",
	emits: ["change-page", "change-show-on-page"],
	props: {
		maxPagesShown: {
			// Кол-во видимых страниц для выбора, не считая двух крайних
			type: Number,
			default: (): number => {
				return 4;
			},
		},
		showPages: {
			// Кол-во объектов на одной странице
			type: Number,
			default: (): number => {
				return 4;
			},
		},
		currentPage: {
			type: Number,
			default: (): number => {
				return 1;
			},
		},
		countItems: {
			// Всего объектов
			type: Number,
			default: (): number => {
				return 1;
			},
		},
		quantityItems: {
			type: Array<number>,
			default: (): Array<number> => {
				return [10, 20, 50];
			},
		},
	},

	setup(props, { emit }) {

		const lastPageNumber = computed<number>(() => {
			const all_pages = Math.ceil(props.countItems / props.showPages);
			return all_pages;
		});

		const pages = computed<number[]>(() => {
			const all_pages = Math.ceil(props.countItems / props.showPages);
			const res: number[] = [];
			for (let p = 1; p <= all_pages; p++) {
				res.push(p);
			}
			return res;
		});

		const showEndPage = computed<boolean>(() => {
			if (pagesShow.value.includes(lastPageNumber.value) || props.countItems <= props.showPages) {
				return false;
			}
			return true;
		});

		const pagesShow = computed<number[]>(() => {
			// Страницы, которые видны в списке
			const slicedArr = pages.value.slice(
				props.currentPage,
				props.currentPage + props.maxPagesShown
			);
			slicedArr.unshift(props.currentPage); // Всегда добавлять текущую страницу
			if (props.currentPage >= 2) {
				slicedArr.unshift(props.currentPage - 1); // Предыдущая страница
			}
			return slicedArr;
		});

		function setPage(page: number): void {
			console.warn("setPage", page);
			emit("change-page", page);
		};
		function setShowOnPage(page: number): void {
			console.warn("setPage", page);
			emit("change-show-on-page", page)
		};

		return{
			setShowOnPage,
			lastPageNumber,
			pages,
			pagesShow,
			showEndPage,
			setPage,

		}

	},
});
